.rg-number-cell {
  justify-content: center !important;
}

.cell-header-budget {
  color: white !important;
  font-family: SoehneBreitKraftig;
  display: flex;
  height: 30px;
  cursor: pointer;
  justify-content: center;
  position: relative;
}

.cell-total-year {
  background: #d5d4d4;
}

.cell-total-month {
  background: #e7e7e7 !important;
}

.negative {
  color: #bb0000 !important;
}

.rg-pane-left {
  z-index: 1;
}

.rg-pane-center-middle {
  z-index: 0;
}

.rg-context-menu-option {
  color: black;
}

.rg-chevron-cell {
  display: flex;
  justify-content: center;
}

.rg-chevron-cell .chevron {
  order: 1;
}

.cell-header-budget-year .chevron,
.cell-header-budget-ratio .chevron {
  pointer-events: none !important;
  cursor: pointer !important;
  position: absolute;
  right: 10px;
}

.cell-header-budget-year.expanded .chevron {
  transform: rotate(0deg) !important;
}

.cell-header-budget-year.collapsed .chevron {
  transform: rotate(90deg) !important;
}

.category-cell {
  padding: 0 20px !important;
}

.sub-category-cell {
  padding: 0 20px 0 40px !important;
}

.accounting-entry {
  justify-content: flex-start;
  padding: 0 20px 0 60px !important;
  font-style: italic;
}

.bold {
  font-family: InstrumentSansBold;
}

.white-bg-cell {
  background: white !important;
}

.rg-context-menu {
  box-shadow: 0px 3px 6px #0000005C !important;
  border-radius: 11px !important;
  padding: 4px 0;
}

.reactgrid-content .rg-context-menu .rg-context-menu-option:hover {
  background: #02226810 !important;
}

.total-hours-spent-cell {
  font-family: InstrumentSansBold;
  font-size: 16px;
  text-align: right;
  flex: 1;
}

.rg-invisibleCell-cell, .rg-unfocusableCell-cell {
  border: none !important;
  justify-content: center;
}

/* TODO : We could eject from CRA. By ejecting , we'd be able to modify sass-loader config and use env variables in scss files to perform this client-based customization
   Instead of duplicating CSS code for each client like we do below
*/

#reactgrid-drivn {
  $primary-color: #022268;
  $secondary-color: #FFB140;
  $primary-color-light: #90A8FC;
  $secondary-color-light: #FFB1401C;
  $spreadsheet-line-contrast-color: #90A8FC4C;
  @import "global";
}

#reactgrid-zenfirst {
  $primary-color: #6633CC;
  $secondary-color: #6BCB77;
  $primary-color-light: #8e74c4;
  $secondary-color-light: #6BCB771C;
  $spreadsheet-line-contrast-color: #90A8FC4C;
  @import "global";
}

#reactgrid-2freporting {
  $primary-color: #3A81D2;
  $secondary-color: #FFB140;
  $primary-color-light: #a0b5f1;
  $secondary-color-light: #FFB1401C;
  $spreadsheet-line-contrast-color: #90A8FC4C;
  @import "global";
}

#reactgrid-bpa {
  $primary-color: #2B41A5;
  $secondary-color: #6D81D9;
  $primary-color-light: #a0b5f1;
  $secondary-color-light: #6D81D91C;
  $spreadsheet-line-contrast-color: #90A8FC4C;
  @import "global";
}

#reactgrid-arc {
  $primary-color: #59A6BD;
  $secondary-color: #FF7733;
  $primary-color-light: #8bacb9;
  $secondary-color-light: #FF77331C;
  $spreadsheet-line-contrast-color: #90A8FC4C;
  @import "global";
}

#ecosia {
  $primary-color: #060F58;
  $secondary-color: #D6A803;
  $primary-color-light: #8bacb9;
  $secondary-color-light: #FF77331C;
  $spreadsheet-line-contrast-color: #90A8FC4C;
  @import "global";
}

.centered-spreadsheet .reactgrid, {
  margin: 0 auto;
}


.rg-dragCell-cell {
  overflow: visible !important;
}

.drag-cell {
  overflow: auto;
}

.drag-cell > .valid {
  display: flex;
  align-items: center;
  overflow: hidden !important;
}

.drag-cell .valid > svg {
  margin-right: 12px;
}

.hidden-row-cell {
  background: #cecece;
  font-style: italic;
}

.rg-cell.rg-frenchNumber-cell {
  justify-content: flex-end;
}