/* common */
.ribbon {
    width: 140px;
    height: 150px;
    overflow: hidden;
    position: absolute;
}

.ribbon::before,
.ribbon::after {
    position: absolute;
    content: '';
    display: block;
    border: 5px solid #8E2222;
}

.ribbon span {
    position: absolute;
    display: block;
    width: 225px;
    padding: 7px 0;
    background-color: #FF4040;
    color: #fff;
    font-family: InstrumentSans;
    font-weight: bold;
    font-size: 14px;
    text-shadow: 0 1px 1px rgba(0, 0, 0, .2);
    text-align: center;
    border: 1px solid white;
}

/* top left*/
.ribbon-top-left {
    top: -10px;
    left: -10px;
}

.ribbon-top-left::before,
.ribbon-top-left::after {
    border-top-color: transparent;
    border-left-color: transparent;
}

.ribbon-top-left::before {
    top: 0;
    right: 24px;
}

.ribbon-top-left::after {
    bottom: 34px;
    left: 0;
}

.ribbon-top-left span {
    right: -25px;
    top: 28px;
    transform: rotate(-45deg);
}
